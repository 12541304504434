import { createAction } from "redux-actions";
import { EDiscoverPunishmentAlertType } from "./discover-models";

export namespace IDiscoverActions {
  export enum Type {
    FILTER__OPEN = "FILTER__OPEN",

    GET__PROFILES__INIT = "GET__PROFILES__INIT",
    GET__PROFILES__SUCCESS = "GET__PROFILES__SUCCESS",
    GET__PROFILES__FAILURE = "GET__PROFILES__FAILURE",

    SET__DISCOVER_POPUP = "SET__DISCOVER_POPUP",

    SET__DISCOVER_ALERT_CONFIRMED = "SET__DISCOVER_ALERT_CONFIRMED",

    SET__DISCOVER_PROFILE_IMPRESSION_TRACKED = "SET__DISCOVER_PROFILE_IMPRESSION_TRACKED",

    GET__DISCOVER_CARDS__INIT = "GET__DISCOVER_CARDS__INIT",
    GET__DISCOVER_CARDS__SUCCESS = "GET__DISCOVER_CARDS__SUCCESS",
    GET__DISCOVER_CARDS__FAILURE = "GET__DISCOVER_CARDS__FAILURE",

    GET__MARK_CARD_AS_VISITED__INIT = "GET__MARK_CARD_AS_VISITED__INIT",
    GET__MARK_CARD_AS_VISITED__SUCCESS = "GET__MARK_CARD_AS_VISITED__SUCCESS",
    GET__MARK_CARD_AS_VISITED__FAILURE = "GET__MARK_CARD_AS_VISITED__FAILURE",
  }

  export const setDiscoverPopup = createAction<any>(Type.SET__DISCOVER_POPUP);

  export const openFilter = createAction<any>(Type.FILTER__OPEN);

  export const initGetProfiles = createAction<any>(Type.GET__PROFILES__INIT);
  export const successGetProfiles = createAction<any>(
    Type.GET__PROFILES__SUCCESS
  );
  export const failureGetProfiles = createAction<any>(
    Type.GET__PROFILES__FAILURE
  );

  export const setDiscoverAlertConfirmed = createAction<{
    type: EDiscoverPunishmentAlertType;
  }>(Type.SET__DISCOVER_ALERT_CONFIRMED);

  export const setDiscoverProfileImpressionTracked = createAction<any>(
    Type.SET__DISCOVER_PROFILE_IMPRESSION_TRACKED
  );

  export const initGetDiscoverCards = createAction(
    Type.GET__DISCOVER_CARDS__INIT
  );
  export const successGetDiscoverCards = createAction<any>(
    Type.GET__DISCOVER_CARDS__SUCCESS
  );
  export const failureGetDiscoverCards = createAction<any>(
    Type.GET__DISCOVER_CARDS__FAILURE
  );

  export const initGetMarkCardAsVisited = createAction(
    Type.GET__MARK_CARD_AS_VISITED__INIT
  );
  export const successGetMarkCardAsVisited = createAction<any>(
    Type.GET__MARK_CARD_AS_VISITED__SUCCESS
  );
  export const failureGetMarkCardAsVisited = createAction<any>(
    Type.GET__MARK_CARD_AS_VISITED__FAILURE
  );
}

export type DiscoverActions = Omit<typeof IDiscoverActions, "Type">;
